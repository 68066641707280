import React from 'react'
import { Container } from 'react-bootstrap'

import pic1 from '../../assets/svg/parts/pic_002_1.png'
import pic2 from '../../assets/svg/parts/pic_002_2.svg'

import row1 from '../../assets/svg/parts/pic_002_r1.svg'

const StepTwo = () => {
  return (
    <Container>
    <div className="s-60"></div>
    <div className="s-m-40"></div>
    <div className="row">
      <div className="col-lg-6">
        <h3 className="title-part two">
          <span className='two c-white'>2</span>
          <span className='txt c-violet'>Зроблено українцями для українців</span>
        </h3>
        <p className="title-text">Наша платформа зроблена українцями для українців. У ній знаходяться українські стартапи та український бізнес, для того щоб прискорити розвиток економіки України та підвищити сомолояльність</p>
      </div>
      <div className="col-lg-6"></div>
    </div>

    <div className="row">
      <div className="col-lg-5">
        <div className="s-60"></div>
        <img src={pic1} alt={'Крок 2'} />
      </div>
      <div className="col-lg-2 m-tc">
        <div className="s-100"></div>
        <img src={row1} alt={'Крок 2'} />
      </div>
      <div className="col-lg-5">
        <img src={pic2} alt={'Крок 2'} />
      </div>
    </div>
  </Container>
  )
}

export {StepTwo}
