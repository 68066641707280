import React from 'react'
import { Container } from 'react-bootstrap'

import logo from '../../assets/svg/logo-footer.svg'

const Footer = () => {
  return (
    <footer>
      <Container>
        <div className="flex jcsb aic">
          <a href='/'>
            <img src={logo} alt={'Лоялко'} height={120} />
          </a>
          <p className='f-text'>Лоялко @ 2023 - Усі права захищено</p>
        </div>
      </Container>
    </footer>
  )
}

export {Footer}
