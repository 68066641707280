import React from 'react'
import './styles/custom.sass'

import { Nav } from './components/parts/Nav'
import { Footer } from './components/parts/Footer'

import { Home } from './components/pages/Home'

function App() {
  return (
    <>
      <Nav />
      <Home />
      <Footer />
    </>
  )
}

export {App}
