import React from 'react'
import { Container } from 'react-bootstrap'

import logo from '../../logo.svg'

const Nav = () => {
  return (
    <nav>
      <Container>
        <div className="flex jcsb aic">
          <h1 className="site-title">
            <span className='d-none'>Лоялко</span>
            <img src={logo} alt={'Лоялко'} height={120} />
          </h1>
          <a href="https://form.typeform.com/to/GgWU96qh" target={'blank'} className='button'>Приєднатися</a>
        </div>
      </Container>
    </nav>
  )
}

export {Nav}
