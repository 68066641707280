import React from 'react'
import { Container } from 'react-bootstrap'

import row from '../../assets/svg/row.svg'

const Hero = () => {
  return (
    <section id='hero'>
      <Container>
        <div className="s-100"></div>
        <div className="s-80"></div>
        <h2 className="title-intro big tc">Платформа <span>Взаємовигідності <img src={row} alt={'стрілка'} /></span></h2>
        <div className="s-10"></div>
        <h3 className='title-intro tc'>Лоялко - стрімке зростання для бізнесу</h3>
        <div className="row">
          <div className="col-lg-8">
            <p className="title-desc"></p>
          </div>
          <div className="col-lg-4">
            <div className="s-50"></div>
            <p className="title-intro desc tc">Платформа, де українські стартапи можуть обмінюватися доступами до API, кредитами SaaS, промо-періодами тощо, отримуючи взаємну вигоду та прискорюючи свій розвиток.</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export {Hero}
