import React from 'react'
import { Container } from 'react-bootstrap'

import pic1 from '../../assets/svg/parts/pic_003_1.png'
import pic2 from '../../assets/svg/parts/pic_003_2.svg'
import pic3 from '../../assets/svg/parts/pic_003_3.svg'

import row1 from '../../assets/svg/parts/pic_003_r1.svg'
import row2 from '../../assets/svg/parts/pic_003_r2.svg'

const StepThree = () => {
  return (
    <Container>
    <div className="s-60"></div>
    <div className="row">
      <div className="col-lg-6 tr">
        <div className="s-10"></div>
        <div className="mx-4">
          <img src={pic1} alt={'Крок 3'} className='w-50' />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="s-100"></div>
        <h3 className="title-part three">
          <span className='three c-white'>3</span>
          <span className='txt c-pink'>Отримуйте лояльних клієнтів</span>
        </h3>
        <p className="title-text">Отримуйте лояльних клієнтів та любителів вашого продукту, які включать його у своє рішення та будуть платити вам. Тому що ви їм дали достатню можливість для тестування, розробки та оцінки</p>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-4 tr">
        <img src={row1} alt={'Крок 3'} />
        <img src={pic2} alt={'Крок 3'} />
      </div>
      <div className="col-lg-2 tc">
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="s-50"></div>
        <img src={row2} alt={'Крок 3'} />
      </div>
      <div className="col-lg-6">
        <div className="s-60"></div>
        <img src={pic3} alt={'Крок 3'} />
      </div>
    </div>
  </Container>
  )
}

export {StepThree}
