import React from 'react'
import { Container } from 'react-bootstrap'

import pic1 from '../../assets/svg/parts/pic_004_1.svg'
import pic2 from '../../assets/svg/parts/pic_004_2.png'
import pic3 from '../../assets/svg/parts/pic_004_3.svg'
import pic4 from '../../assets/svg/parts/pic_004_4.svg'

import row1 from '../../assets/svg/parts/pic_004_r1.svg'
import row2 from '../../assets/svg/parts/pic_004_r2.svg'
import row3 from '../../assets/svg/parts/pic_004_r3.svg'

const StepFour = () => {
  return (
    <Container>
    <div className="s-60"></div>
    <div className="s-m-40"></div>
    <div className="row">
      <div className="col-lg-7">
        <div className="s-100"></div>
        <div className="tl">
          <h3 className="title-part four">
            <span className='four c-white'>4</span>
            <span className='txt c-marine'>Взаємовигідний обмін</span>
          </h3>
          <p className="title-text w-60">Розширюйте поширення вашого продукту серед B2B та B2C аудиторії для отримання лояльних клієнтів</p>
        </div>
        <div className="s-40"></div>
      </div>
      <div className="col-lg-5"><img src={pic1} alt={'Крок 4'} /></div>
    </div>
    <div className="row">
      <div className="col-lg-5 m-hide"></div>
      <div className="col-lg-2 m-tc">
        <img src={row1} alt={'Крок 4'} className='tr m-tc' />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 m-hide"></div>
      <div className="col-lg-3 m-tc">
        <img src={pic2} alt={'Крок 4'} />
      </div>
      <div className="col-lg-1 m-hide"></div>
      <div className="col-lg-4">
        <div className="s-60"></div>
          <p className="title-text nm">
          Все має вартість, у тому числі й лояльність. Давайте більш вигідні умови та нарощуйте кількість нових клієнтів для вашого бізнесу</p>
      </div>
    </div>
    <div className="row">
      <div className="s-m-40"></div>
      <div className="col-lg-1 m-hide"></div>
      <div className="col-lg-4">
        <div className="s-80"></div>
        <p className="title-text nm">Наша платформа дозволить вам знайти потенційних бізнес-партнерів, ресурси і все це від українців та для українців</p>
      </div>
      <div className="col-lg-1 m-hide"></div>
      <div className="col-lg-3 m-tc">
        <img src={row2} alt={'Крок 4'} />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-1 m-hide"></div>
      <div className="col-lg-5">
        <div className="tl w-100">
          <div className="s-20"></div>
          <div className="flex jcsb aic">
            <img src={pic3} alt={'Крок 4'} />
            <img src={row3} alt={'Крок 4'} />
          </div>
          <div className="s-80"></div>
          <p className="title-text nm">Лоялко – це можливість досвідченому українському бізнесу та продуктовим компаніям допомогти розвитку стартап-екосистеми України</p>
        </div>
        <div className="s-100"></div>
      </div>
      <div className="col-lg-6">
        <div className="s-50"></div>
        <img src={pic4} alt={'Крок 4'} />
      </div>
    </div>
    <div className="s-m-40"></div>
    <div className="w-100 tc my-4">
      <a href="https://form.typeform.com/to/GgWU96qh" target={'_blank'} className='button marine' rel="noreferrer">Приєднатися</a>
    </div>
  </Container>
  )
}

export {StepFour}
