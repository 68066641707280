import React from 'react'
import { StepOne } from '../parts/StepOne'
import { StepTwo } from '../parts/StepTwo'
import { StepThree } from '../parts/StepThree'
import { StepFour } from '../parts/StepFour'
import { Hero } from '../parts/Hero'

const Home = () => {
  return (
    <>
      <Hero />
      <StepOne />
      <StepTwo />
      <StepThree />
      <StepFour />
    </>
  )
}

export {Home}
