import React from 'react'
import { Container } from 'react-bootstrap'

import pic1 from '../../assets/svg/parts/pic_001_1.png'
import pic2 from '../../assets/svg/parts/pic_001_2.svg'
import pic3 from '../../assets/svg/parts/pic_001_3.svg'
import pic4 from '../../assets/svg/parts/pic_001_4.svg'

import row1 from '../../assets/svg/parts/pic_001_r1.svg'
import row2 from '../../assets/svg/parts/pic_001_r2.svg'


const StepOne = () => {
  return (
    <Container>
      <div className="s-60"></div>
      <div className="row">
        <div className="col-lg-4">
          <img src={pic1} alt={'Крок 1'} />
        </div>
        <div className="col-lg-2">
          <div className="s-100"></div>
          <div className="s-100"></div>
          <img src={row1} alt={'Стрілка 1'} />
        </div>
        <div className="col-lg-6">
          <div className="s-100"></div>
          <h3 className="title-part one">
            <span className='one c-white'>1</span>
            <span className='txt c-blue'>Знайдіть свій промокод</span>
          </h3>
          <p className="title-text">Поділіться з учасниками Лоялко супер-вигідною пропозицією (демо-доступом на місяць або кредитами на використання вашого продукту) для того, щоб інші учасники могли його включити та спробувати ваш продукт як частину свого бізнесу</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 m-hide"></div>
        <div className="s-m-40"></div>
        <div className="col-lg-4"><img src={pic2} alt={'Крок 1'} /></div>
        <div className="col-lg-4 tl">
          <div className="s-100"></div>
          <div className="s-m-40"></div>
          <p className="title-text tс nm w-60">Наростіть лояльність. Запропонуйте дискаунт</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 tr">
          <img src={pic3} alt={'Крок 1'} />
          <div className="s-20"></div>
          <div className="flex">
            <div className="w-30"></div>
            <p className="title-text nm tc w-70">Ваш новий бізнес-партнер</p>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="s-100"></div>
          <div className="s-100"></div>
          <img src={row2} alt={'Стрілка 1'} />
        </div>
        <div className="col-lg-5">
          <div className="s-100"></div>
          <p className="title-text nm w-60 ma">Діліться знижками, кодами, промокодами, тестовими пільгами, api тощо</p>
          <div className="s-60"></div>
          <img src={pic4} alt={'Крок 1'} />
        </div>
      </div>
    </Container>
  )
}

export {StepOne}
